<template>
  <div class="page">
    <el-form
      ref="form"
      label-width="120px"
      :loading="form_loading"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="运费模板" prop="name" style="margin-top: 30px">
        <el-input
          v-model="form.name"
          size="small"
          style="width: 400px"
          placeholder="请输入模板名称"
        />
        <span style="margin-left: 30px"
          >是否启用
          <el-tooltip :content="form.start == 1 ? '是' : '否'" placement="top">
            <el-switch
              v-model="form.start"
              active-color="#13ce66"
              :active-value="1"
              inactive-color="#ff4949"
              :inactive-value="0"
            />
          </el-tooltip>
        </span>
      </el-form-item>
      <el-form-item label="模板图片">
        <div
          class="picItem"
          @click="showSelecter = true"
          style="cursor: pointer"
        >
          <el-image
            v-if="form.pic"
            :src="form.pic"
            style="width: 100px; height: 100px; border-radius: 10px"
          />
          <el-button
            v-else
            icon="el-icon-plus"
            plain
            style="width: 100px; height: 100px; border-radius: 10px"
          />
        </div>
      </el-form-item>
      <el-form-item label="简单描述" prop="name_title">
        <el-input
          type="textarea"
          :rows="2"
          v-model="form.name_title"
          size="small"
          style="width: 400px"
          placeholder="请输入描述"
        />
      </el-form-item>
      <el-form-item label="参与会员">
        <el-radio-group v-model="form.join_condition">
          <el-radio :label="0">所有会员</el-radio>
          <el-radio v-for="item in levelArr" :key="item.id" :label="item.id">{{
            item.name
          }}</el-radio>
        </el-radio-group>
        <el-checkbox
          v-model="form.pay"
          :true-label="1"
          :false-label="0"
          style="margin-left: 20px"
          >付费会员</el-checkbox
        >
      </el-form-item>
      <el-form-item label="计费模式" prop="billing_pattern">
        <el-radio-group size="small" v-model="form.billing_pattern"
          ><!--  @change="selectPattern" -->
          <el-radio-button :label="0">一口价</el-radio-button>
          <el-radio-button :label="1">常规</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="form.billing_pattern != 1" label="一口价">
        <el-input
          type="number"
          v-model="form.fixed_price"
          :min="0"
          size="small"
          style="width: 400px"
          placeholder="请输入"
        >
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item v-show="form.billing_pattern == 1" label="首重:">
        <el-input
          type="number"
          v-model="form.general_json.first_weight"
          :min="0"
          size="small"
          style="width: 100px"
          placeholder="请输入"
        />
        克(g),费用
        <el-input
          type="number"
          v-model="form.general_json.first_fee"
          :min="0"
          size="small"
          style="width: 100px"
          placeholder="请输入"
        />
        元;次重:
        <el-input
          type="number"
          v-model="form.general_json.second_weight"
          :min="0"
          size="small"
          style="width: 100px"
          placeholder="请输入"
        />
        克(g),费用
        <el-input
          type="number"
          v-model="form.general_json.second_fee"
          :min="0"
          size="small"
          style="width: 100px"
          placeholder="请输入"
        />
        元;体积重系数
        <el-input
          type="number"
          v-model="form.general_json.volume"
          :min="0"
          size="small"
          style="width: 100px"
          placeholder="例:5000"
        />
      </el-form-item>
      <span style="margin-left: 20px; font-size: 24px">限制规则</span>
      <el-form-item label="实付金额">
        <div>
          <el-checkbox
            v-model="form.astrict_json.money_type1"
            :true-label="1"
            :false-label="0"
          >
            订单实付金额(扣除运费后)必须大于等于
            <el-input
              type="number"
              v-model="form.astrict_json.money1"
              size="small"
              style="width: 100px"
              placeholder="请输入"
            />
            元
          </el-checkbox>
        </div>
        <div>
          <el-checkbox
            v-model="form.astrict_json.money_type2"
            :true-label="1"
            :false-label="0"
          >
            订单实付金额(扣除运费后)必须小于
            <el-input
              type="number"
              v-model="form.astrict_json.money2"
              size="small"
              style="width: 100px"
              placeholder="请输入"
            />
            元
          </el-checkbox>
        </div>
      </el-form-item>
      <el-form-item label="物品数量" prop="number_astrict">
        <el-radio-group size="small" v-model="form.astrict_json.number_astrict">
          <el-radio-button :label="0">不限制</el-radio-button>
          <el-radio-button :label="1">绝对限制</el-radio-button>
          <el-radio-button :label="2">增加费用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
          v-show="form.astrict_json.number_astrict == 1"
          label="限制数量"
      >
        <el-input
            type="number"
            v-model="form.astrict_json.max_number"
            size="small"
            :min="1"
            style="width: 100px"
            placeholder="请输入"
        />
        件
      </el-form-item>
      <el-form-item
        v-show="form.astrict_json.number_astrict == 2"
        label="每件增收"
      >
        <el-input
          type="number"
          v-model="form.astrict_json.exceed_cost"
          size="small"
          style="width: 100px"
          placeholder="请输入"
        />
        元
      </el-form-item>
      <el-form-item label="派送限制" prop="delivery_astrict">
        <el-radio-group
          size="small"
          v-model="form.astrict_json.delivery_astrict"
        >
          <el-radio-button :label="0">该运费模板只派送以下区域</el-radio-button>
          <el-radio-button :label="1">该运费模板不派送以下区域</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="区域列表">
        <div>
          <el-select
            v-model="label_id"
            size="small"
            placeholder="请选择区域标签"
            style="width: 200px"
          >
            <el-option
              v-for="item in labelArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-button
            type="primary"
            style="margin-left: 10px"
            size="small"
            @click="addAreaArr(1)"
          >
            快捷导入
          </el-button>
          <!-- , checkStrictly: true -->
          <el-cascader
            ref="cascader"
            v-model="areaMsg2"
            clearable
            size="small"
            :options="areaList"
            placeholder="请选择区域"
            :props="{
              children: 'children',
              label: 'name',
              value: 'id',
              multiple: true,
            }"
            collapse-tags
            style="width: 300px; margin-left: 10px"
          >
            <template slot-scope="{ node, data }">
              <span>{{ data.name }}</span>
              <span v-if="!node.isLeaf">({{ data.children.length }})</span>
            </template>
          </el-cascader>
          <el-button
            type="primary"
            style="margin-left: 10px"
            size="small"
            @click="addAreaArr(2)"
          >
            添加
          </el-button>
        </div>
        <div>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="handleDelete"
          >
            批量删除
          </el-button>
        </div>
        <el-table
          :data="list"
          size="small"
          border
          style="width: 500px"
          height="250"
          @selection-change="setSelectRows"
        >
          <el-table-column
            align="center"
            show-overflow-tooltip
            type="selection"
          />
          <el-table-column align="center" label="区域" prop="name">
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <span style="margin-left: 20px; font-size: 24px">快递承运商</span>
      <el-form-item prop="logi_id" label="选择快递">
        <el-select
          v-model="form.logi_id"
          size="small"
          multiple
          style="width: 400px; margin-left: 10px"
          placeholder="请选择快递公司"
        >
          <el-option
            v-for="item in courierList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="save" style="margin: 0 0 50px 50px"
      >提 交</el-button
    >
    <fileSelect
      :visible.sync="showSelecter"
      title="选择图片"
      state="image"
      @confirm="showSelecterSubmit"
    ></fileSelect>
  </div>
</template>

<script>
import {
  apiGetAreaMsg,
  apiGetAreaLabel,
  apiGetCourierList,
  apiUpdateCourierDemo,
  apiGetCourierInfo,
  apiGetLevelList,
} from "@/request/api";
import fileSelect from "@/views/components/fileSelect";

export default {
  name: "freightDemo.vue",
  components: {
    fileSelect,
  },
  data() {
    return {
      form_loading: false,
      showSelecter: false,
      labelArr: [],
      areaList: [],
      courierList: [],
      list: [],
      label_id: "",
      areaMsg2: [],
      idArr: [],
      levelArr: [],
      form: {
        id: this.$route.query.id,
        pic: "",
        name: "", //模板名称
        start: 1, //是否启用 0否1是
        name_title: "", //模板简介
        join_condition: 0, //参与会员 0所有
        pay: 0, //是否付费会员 0否1是
        billing_pattern: 0, //计费模式 0一口价1常规
        fixed_price: 0, //一口价费用
        general_json: {
          //常规
          first_weight: 200, //首重
          first_fee: 0, //首重费用
          second_weight: 500, //次重
          second_fee: 0, //次重费用
          volume: 5000, //体积重
        },
        astrict_json: {
          //限制规则
          money_type1: 0, //实付金额类型(大于等于)
          money1: "", //大于等于金额
          money_type2: 0, //实付金额类型(小于)
          money2: "", //小于金额
          number_astrict: 0, //物品数量限制类型
          exceed_cost: "", //增收金额
          delivery_astrict: 0, //派送限制类型
          delivery_city: [], //限制区域
          max_number:1,
        },
        logi_id: [], //快递公司
      },
      rules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        logi_id: [
          { required: true, message: "请选择快递公司", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getCourierInfo();
    }
    this.getLevelList();
    this.fetchData();
    this.getLabelList();
    this.getCourierList();
  },
  methods: {
    showSelecterSubmit(data) {
      this.showSelecter = false;
      this.form.pic = data[0].thumb;
    },
    save() {
      if (this.form.billing_pattern == 1) {
        this.form.fixed_price = "";
      } else {
        this.form.general_json = {
          //常规
          first_weight: "", //首重
          first_fee: "", //首重费用
          second_weight: "", //次重
          second_fee: "", //次重费用
          volume: "", //体积重
        };
      }
      this.form.astrict_json.delivery_city = this.idArr;
      this.form.astrict_json.exceed_cost =
        this.form.astrict_json.number_astrict == 2
          ? parseFloat(this.form.astrict_json.exceed_cost)
          : "";
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          apiUpdateCourierDemo(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              // this.form = {
              //   pic: "",
              //   name: "", //模板名称
              //   start: 1, //是否启用 0否1是
              //   name_title: "", //模板简介
              //   join_condition: 0, //参与会员 0所有
              //   pay: 0, //是否付费会员 0否1是
              //   billing_pattern: 0, //计费模式 0一口价1常规
              //   fixed_price: "", //一口价费用
              //   general_json: {
              //     //常规
              //     first_weight: 200, //首重
              //     first_fee: 0, //首重费用
              //     second_weight: 500, //次重
              //     second_fee: 0, //次重费用
              //     volume: 5000, //体积重
              //   },
              //   astrict_json: {
              //     //限制规则
              //     money_type1: 0, //实付金额类型(大于等于)
              //     money1: "", //大于等于金额
              //     money_type2: 0, //实付金额类型(小于)
              //     money2: "", //小于金额
              //     number_astrict: 0, //物品数量限制类型
              //     exceed_cost: "", //增收金额
              //     delivery_astrict: 0, //派送限制类型
              //     delivery_city: [], //限制区域
              //     max_number:1,
              //   },
              //   logi_id: [], //快递公司
              // };
              // this.idArr = [];
              // this.list = [];
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //批量删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm("你确定要删除当前项吗", null, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.list.map((val, i) => {
            if (val.id == row.id) {
              this.list.splice(i, 1);
            }
          });
          this.idArr.map((val, i) => {
            if (val == row.id) {
              this.idArr.splice(i, 1);
            }
          });
        });
      } else {
        this.$confirm("你确定要删除所有选中项吗", null, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.list.map((val, i) => {
              if (val.id == this.selectRows[j].id) {
                this.list.splice(i, 1);
              }
            });
            this.idArr.map((val, i) => {
              if (val == this.selectRows[j].id) {
                this.idArr.splice(i, 1);
              }
            });
          }
        });
      }
    },
    //添加区域
    addAreaArr(num) {
      if (num == 1) {
        for (let i = 0; i < this.labelArr.length; i++) {
          if (this.label_id == this.labelArr[i].id) {
            this.pushAreaList(this.labelArr[i].children);
            return;
          }
        }
      } else if (num == 2) {
        console.log(this.areaMsg2);
        for (let i = 0; i < this.areaMsg2.length; i++) {
          const tempid = this.areaMsg2[i][this.areaMsg2[i].length - 1];
          const tempname = this.getAreaStr(this.areaList, this.areaMsg2[i]);
          this.list.push({ id: tempid, name: tempname });
          this.idArr.push(tempid);
        }
        const map = new Map();
        this.list = this.list.filter(
          (key) => !map.has(key.id) && map.set(key.id, 1)
        );
        this.areaMsg2 = [];
      }
    },
    //获取添加区域全称
    getAreaStr(data, arr) {
      let result = [];
      const childrenTreeToLabel = (data, arr, result) => {
        for (let j = 0; j < data.length; j++) {
          if (arr[0] == data[j].id) {
            result.push(data[j].name);
            if (data[j].children && arr.length > 1) {
              return childrenTreeToLabel(
                data[j].children,
                arr.slice(1),
                result
              );
            }
            break;
          }
        }
      };
      childrenTreeToLabel(data, arr, result);
      return result.join("/");
    },
    //快捷导入区域
    pushAreaList(data) {
      const tempArr = this.list;
      for (let i = 0; i < data.length; i++) {
        tempArr.push({ id: data[i].id, name: data[i].name });
        this.idArr.push(data[i].id);
      }
      const map = new Map();
      // 对id属性进行去重
      this.list = tempArr.filter(
        (key) => !map.has(key.id) && map.set(key.id, 1)
      );
      this.label_id = "";
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    //编辑获取快递详情
    getCourierInfo() {
      apiGetCourierInfo({ id: this.$route.query.id }).then((res) => {
        if (res.code == 200) {
          this.form = res.data;
          const templogi = [];
          for (let i = 0; i < res.data.logi_id.length; i++) {
            templogi.push(parseInt(res.data.logi_id[i]));
          }
          this.form.logi_id = templogi;
          this.form.join_condition = parseInt(res.data.join_condition);
          this.list = res.data.astrict_json.delivery_city;
          const length = res.data.astrict_json.delivery_city.length;
          this.idArr = [];
          for (let j = 0; j < length; j++) {
            this.idArr.push(res.data.astrict_json.delivery_city[j].id);
          }
        }
      });
    },
    //获取用户等级列表(不分页)
    getLevelList() {
      apiGetLevelList().then((res) => {
        if (res.code == 200) {
          this.levelArr = res.data;
        }
      });
    },
    //获取用户标签列表
    getLabelList() {
      apiGetAreaLabel({ id: "" }).then((res) => {
        if (res.code == 200) {
          this.labelArr = res.data;
        }
      });
    },
    //获取区域无限极数据
    async fetchData() {
      apiGetAreaMsg(this.queryForm).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data;
        }
      });
    },
    //获取快递商列表
    getCourierList() {
      apiGetCourierList().then((res) => {
        if (res.code == 200) {
          this.courierList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #fff;
  padding: 20px;
}
</style>
